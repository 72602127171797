import React from "react";
import jwt_decode from "jwt-decode";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, user: JSON.parse(localStorage.getItem("user")) };
    case "SIGN_OUT_SUCCESS":
      return { ...state, user: {} };
      case "MESSAGE_SHOW":
        return { ...state, message: true };
      case "MESSAGE_REMOVE":
        return { ...state, message: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

 
 function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, { 
    user: JSON.parse(localStorage.getItem("user")),
    message: true,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

 
export { UserProvider, useUserState, useUserDispatch, messageShow, signOut, authUser, superUser };

// ###########################################################
 


function authUser(authToken, dispatch, navigate){ 
 
  localStorage.setItem('token', authToken); 

  const decode = jwt_decode(authToken); 
  localStorage.setItem('user', JSON.stringify(decode)); 
  
  if (decode.role === 'ADMIN') {
    navigate("/auth/chatBot");         
  } else if (decode.role === 'SUPERADMIN') {
    navigate('/super-admin/users');
  } 
          
  dispatch({ type: 'LOGIN_SUCCESS' }) 

 
}
 
function superUser(authToken, dispatch, navigate){ 
   
  localStorage.setItem('token', authToken);  
  const decode = jwt_decode(authToken); 
  localStorage.setItem('user', JSON.stringify(decode) ); 
  navigate('/app/users');    
  dispatch({ type: 'LOGIN_SUCCESS' }) 
 
}




function signOut(dispatch) {
  localStorage.removeItem("token"); 
  localStorage.removeItem('user'); 
  dispatch({ type: "SIGN_OUT_SUCCESS" }); 
}
 

function messageShow(dispatch, message){   
  dispatch({ type: message });
}

