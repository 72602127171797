import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import Router from './routes/Router';
import { UserProvider } from "./context/UserContext";

import { baselightTheme } from "./theme/DefaultColors";
import "./scroll.css"
;
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID}>
      <UserProvider>
      <ThemeProvider theme={theme}>

        <CssBaseline />
        {routing}

      </ThemeProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
