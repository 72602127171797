import React, { lazy } from 'react';
import { Navigate,  useLocation, } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { useUserState } from "../context/UserContext";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const CustomerLayout = Loadable(lazy(() => import('../layouts/CustomerLayout/CustomerLayout')));
const CustomLayout = Loadable(lazy(() => import('../layouts/CustomerLayout/CustomLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
//const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')))
  
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const SuperAdminLogin = Loadable(lazy(() => import('../views/authentication/SALogin')));
const HomePage = Loadable(lazy(() => import('../views/pages/Home/Home')));

const CreateChatBot = Loadable(lazy(() => import('../views/pages/CreateChatBot')));
const DeleteChatBot = Loadable(lazy(() => import('../views/pages/DeleteChatBot')));
const Users = Loadable(lazy(() => import('../views/pages/superAdmin/Users')));
const CustomerConversation = Loadable(lazy(() => import('../views/pages/CustomerCon/Conversation')));
const TransactionSuper = Loadable(lazy(() => import('../views/pages/superAdmin/Transaction')));
const Plans = Loadable(lazy(() => import('../views/pages/superAdmin/Plans')));
const Billing = Loadable(lazy(() => import('../views/pages/Billing')));
const ApiData = Loadable(lazy(() => import('../views/pages/ApiData')));

const TrainingData = Loadable(lazy(() => import('../views/pages/Training')));
const Agent = Loadable(lazy(() => import('../views/pages/Agent'))); 
const Implementation = Loadable(lazy(() => import('../views/pages/Implementation')));
const Conversations = Loadable(lazy(() => import('../views/pages/Conversations'))); 
//const Settings = Loadable(lazy(() => import('../views/pages/Settings')));
 const Support = Loadable(lazy(() => import('../views/pages/Support')));
 const Widget = Loadable(lazy(() => import('../views/pages/Widget')));
 const PreviewIframe = Loadable(lazy(() => import('../views/pages/PreviewIframe')));

const Transaction = Loadable(lazy(() => import('../views/pages/Transaction')));

const Verify = Loadable(lazy(() => import('../views/authentication/Verify'))); 
const Reset = Loadable(lazy(() => import('../views/authentication/Reset'))); 


const Router = [
  {
    path: '/auth',
    element: <RequireAuth > <FullLayout /> </RequireAuth>,
    children: [
      { path: '/auth', element: <Navigate to="/chatBot" /> },
    //  { path: '/auth/dashboard', exact: true, element: <Dashboard /> },
       { path: '/auth/chatBot', exact: true, element: <CreateChatBot /> },
       { path: '/auth/deleteChatBot/:id', exact: true, element: <DeleteChatBot /> },
       { path: '/auth/:id/billing', exact: true, element: <Billing /> },
       { path: '/auth/:id/apidata', exact: true, element: <ApiData /> },
      { path: '/auth/:id/trainingData', exact: true, element: <TrainingData /> }, 
      { path: '/auth/:id/agent', exact: true, element: <Agent /> },
      { path: '/auth/:id/conversations', exact: true, element: <Conversations /> }, 
      { path: '/auth/:id/implementation', exact: true, element: <Implementation /> },
      // { path: '/auth/:id/settings', exact: true, element: <Settings /> },
      { path: '/auth/:id/support', exact: true, element: <Support /> },
      { path: '/auth/:id/transaction', exact: true, element: <Transaction /> },
      { path: '/auth/:id/widget', exact: true, element: <Widget /> },

      // { path: '/sample-page', exact: true, element: <SamplePage /> },
      // { path: '/icons', exact: true, element: <Icons /> },
     //{ path: '/ui/typography', exact: true, element: <TypographyPage /> },
      // { path: '/ui/shadow', exact: true, element: <Shadow /> },
      // { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/super-admin',
    element: <SuperAuth> <FullLayout />  </SuperAuth>,
    children: [
      { path: '/super-admin', element: <Navigate to="/users" /> },
      { path: '/super-admin/users', exact: true, element: <Users /> },
      { path: '/super-admin/transaction', exact: true, element: <TransactionSuper /> },
      { path: '/super-admin/plans', exact: true, element: <Plans /> },
      // ...
    ],
  },
  {
    path: '/customer',
    element:  <CustomLayout />,
    children: [
      { path: '/customer', exact: true, element: <CustomerConversation/> },

      // ...
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [ 
      { path: '', element: <HomePage /> },
      { path: '404', element: <Error /> },
      { path: 'register', element: <Register /> },
      { path: 'verify', element: <Verify /> },
      { path: 'author369', element: <SuperAdminLogin /> },
      { path: 'changepassword', element: <Reset /> }, 
      { path: 'login', element: <Login /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/auth/preview', exact: true, element: <PreviewIframe /> },
    ],
  },
];

export default Router; 
// function useAuth() {
//   var { user } = useUserState();  
//   return React.useContext(user);
// }

function RequireAuth({ children, superChildern }) {
  var { user } = useUserState(); 
  //  var auth = JSON.parse(user); 
  let location = useLocation(); 

  if (user?.role ==="ADMIN") { 
    return children;
  }
  else{
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
 
}

function SuperAuth({ children }) {
  var { user } = useUserState(); 
  //  var auth = JSON.parse(user); 
  let location = useLocation(); 

  if (user?.role ==="SUPERADMIN") { 
    return children;
  } else{
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
 
}

